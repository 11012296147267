import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    // 1. 로그인 페이지
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "user" */ '../views/Login.vue')
  },
  {
    // 1-1. 회원가입 페이지
    path: '/join',
    name: 'Join',
    component: () => import(/* webpackChunkName: "user" */ '../views/Join.vue')
  },
  {
    // 2-1. 마이페이지
    path: '/mypage',
    name: 'Mypage',
    component: () => import(/* webpackChunkName: "user" */ '../views/Mypage.vue')
  },
  {
    // 2-2. 정보수정 페이지(헤더)
    path: '/myinfo',
    name: 'MyInfo',
    component: () => import(/* webpackChunkName: "user" */ '../views/Myinfo.vue')
  },
  {
    // 3-1. POI List
    path: '/poi_list',
    name: 'PoiList',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiList.vue')
  },
  {
    // 3-2. POI 보기
    path: '/poi_view/:no',
    name: 'PoiView',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiView.vue')
  },
  {
    // 3-3. POI 수정
    path: '/poi_edit/:no',
    name: 'PoiEdit',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiEdit.vue')
  },
  {
    // 3-4. POI 쓰기
    path: '/poi_create',
    name: 'PoiCreate',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiCreate.vue')
  },
  {
    // 3-5-1. POI 수정 할당
    path: '/poi_assign',
    name: 'PoiAssign',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiAssign.vue')
  },
  {
    // 3-5-2. POI 일자별 검수요청량 테이블 보기
    path: '/poi_assign_day_table',
    name: 'PoiAssignDayTable',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiAssignDayTable.vue')
  },
  {
    // 3-6-1. POI 수정 작업
    path: '/poi_edit_list',
    name: 'PoiEditList',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiEditList.vue')
  },
  {
    // 3-6-2. POI 수정 작업 보기
    path: '/poi_edit_view/:no',
    name: 'PoiEditView',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiEditView.vue')
  },
  {
    // 3-7-1. POI 검수 할당
    path: '/poi_assign_confirm',
    name: 'PoiAssignConfirm',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiAssignConfirm.vue')
  },
  {
    // 3-7-2. POI 일자별 검수량 테이블 보기
    path: '/poi_confirm_day_table',
    name: 'PoiConfirmDayTable',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiConfirmDayTable.vue')
  },
  {
    // 3-8-1. POI 검수 작업
    path: '/poi_confirm_list',
    name: 'PoiConfirmList',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiConfirmList.vue')
  },
  {
    // 3-8-2. POI 검수 작업 보기
    path: '/poi_confirm_view/:no',
    name: 'PoiConfirmView',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiConfirmView.vue')
  },
  {
    // 3-9. POI 검수 작업 현황 체크
    path: '/poi_confirm_check_list',
    name: 'PoiConfirmCheckList',
    component: () => import(/* webpackChunkName: "poi" */ '../views/PoiConfirmCheckList.vue')
  },
  {
    // 4-1. 이미지 업로드 List
    path: '/img_list',
    name: 'ImgList',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgList.vue')
  },
  {
    // 4-2. 이미지 등록
    path: '/img_create/:img_no',
    name: 'ImgCreate',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgCreate.vue')
  },
  {
    // 4-3. 이미지 보기
    path: '/img_view/:img_no',
    name: 'ImgView',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgView.vue')
  },
  {
    // 4-4-1. 더미이미지 수정 리스트
    path: '/img_edit_list',
    name: 'ImgEditList',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgEditList.vue')
  },
  {
    // 4-4-2. 더미이미지 수정 보기
    path: '/img_edit_view/:img_no',
    name: 'ImgEditView',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgEditView.vue')
  },
  {
    // 4-4-3. 더미이미지 수정 할당
    path: '/img_edit_assign',
    name: 'ImgEditAssign',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgEditAssign.vue')
  },
  {
    // 4-4-4. 더미이미지 수정 할당 > 일별 작업량 보기
    path: '/img_edit_day_table',
    name: 'ImgEditDayTable',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgEditDayTable.vue')
  },
  {
    // 4-5-1. 더미이미지 수정 검수 리스트
    path: '/img_edit_confirm_list',
    name: 'ImgEditConfirmList',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgEditConfirmList.vue')
  },
  {
    // 4-5-2. 더미이미지 수정 검수 보기
    path: '/img_edit_confirm_view/:img_no',
    name: 'ImgEditConfirmView',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgEditConfirmView.vue')
  },
  {
    // 4-5-3. 더미이미지 수정 검수 할당
    path: '/img_edit_confirm_assign',
    name: 'ImgEditConfirmAssign',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgEditConfirmAssign.vue')
  },
  {
    // 4-5-4. 더미이미지 수정 검수 할당 > 일별 작업량 보기
    path: '/img_edit_confirm_day_table',
    name: 'ImgEditConfirmDayTable',
    component: () => import(/* webpackChunkName: "img" */ '../views/ImgEditConfirmDayTable.vue')
  },
  {
    // 5-1. 이미지 검수 List
    path: '/img_confirm_list',
    name: 'ImgConfirmList',
    component: () => import(/* webpackChunkName: "confirm" */ '../views/ImgConfirmList.vue')
  },
  {
    // 5-2. 이미지 검수 보기 및 검수하기
    path: '/img_confirm_view/:img_no',
    name: 'ImgConfirmView',
    component: () => import(/* webpackChunkName: "confirm" */ '../views/ImgConfirmView.vue')
  },
  {
    // 5-3. 이미지 검수 할당 List
    path: '/img_confirm_assign',
    name: 'ImgConfirmAssign',
    component: () => import(/* webpackChunkName: "confirm" */ '../views/ImgConfirmAssign.vue')
  },
  {
    // 5-4. 이미지 뷰어 List
    path: '/img_viewer_list',
    name: 'ImgViewerList',
    component: () => import(/* webpackChunkName: "confirm" */ '../views/ImgViewerList.vue')
  },
  {
    // 5-5. 이미지 뷰어 view
    path: '/img_viewer_view/:img_no',
    name: 'ImgViewerView',
    component: () => import(/* webpackChunkName: "confirm" */ '../views/ImgViewerView.vue')
  },

  {
    // 6-1. 권한관리
    path: '/user_perm',
    name: 'UserPerm',
    component: () => import(/* webpackChunkName: "user" */ '../views/UserPerm.vue')
  },

  {
    // 7-1. 번역 List
    path: '/trans_list/:lan',
    name: 'TransList',
    component: () => import(/* webpackChunkName: "user" */ '../views/TransList.vue')
  },
  {
    // 7-2. 번역 View
    path: '/trans_view/:lan/:tno',
    name: 'TransView',
    component: () => import(/* webpackChunkName: "user" */ '../views/TransView.vue')
  },

  {
    // 8-1-1. Q&A 캡션&질문 등록 할당
    path: '/question_assign',
    name: 'QuestionAssign',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionAssign.vue')
  },
  {
    // 8-1-2. Q&A 캡션&질문 등록 List
    path: '/question_list',
    name: 'QuestionList',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionList.vue')
  },
  {
    // 8-1-3. Q&A 캡션&질문 등록 View
    path: '/question_view/:no',
    name: 'QuestionView',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionView.vue')
  },
  {
    // 8-1-4. Q&A 캡션&질문 등록 월/일자/워커별 작업현황 테이블
    path: '/question_day_table',
    name: 'QuestionDayTable',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionDayTable.vue')
  },
  {
    // 8-1-5. Q&A 캡션&질문 등록 월/일자/워커별 작업현황 테이블2
    path: '/question_day_table2',
    name: 'QuestionDayTable2',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionDayTable2.vue')
  },
  {
    // 8-2-1. Q&A 캡션&질문 검수 할당
    path: '/question_confirm_assign',
    name: 'QuestionConfirmAssign',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionConfirmAssign.vue')
  },
  {
    // 8-2-2. Q&A 캡션&질문 검수 List
    path: '/question_confirm_list',
    name: 'QuestionConfirmList',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionConfirmList.vue')
  },
  {
    // 8-2-3. Q&A 캡션&질문 검수 View
    path: '/question_confirm_view/:no',
    name: 'QuestionConfirmView',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionConfirmView.vue')
  },
  {
    // 8-2-4. Q&A 캡션&질문 검수 월/일자/워커별 작업현황 테이블
    path: '/question_confirm_day_table',
    name: 'QuestionConfirmDayTable',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionConfirmDayTable.vue')
  },
  {
    // 8-2-5. Q&A 캡션&질문 검수 월/일자/워커별 작업현황 테이블 순수검수량
    path: '/question_confirm_day_table2',
    name: 'QuestionConfirmDayTable2',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionConfirmDayTable2.vue')
  },
  {
    // 8-3-1. Q&A 답변 등록 할당
    path: '/answer_assign',
    name: 'AnswerAssign',
    component: () => import(/* webpackChunkName: "qna" */ '../views/AnswerAssign.vue')
  },
  {
    // 8-3-2. Q&A 답변 등록 List
    path: '/answer_list',
    name: 'AnswerList',
    component: () => import(/* webpackChunkName: "qna" */ '../views/AnswerList.vue')
  },
  {
    // 8-3-3. Q&A 답변 등록 View
    path: '/answer_view/:no',
    name: 'AnswerView',
    component: () => import(/* webpackChunkName: "qna" */ '../views/AnswerView.vue')
  },
  {
    // 8-3-4. Q&A 답변 등록 월/일자/워커별 작업현황 테이블
    path: '/answer_day_table',
    name: 'AnswerDayTable',
    component: () => import(/* webpackChunkName: "qna" */ '../views/AnswerDayTable.vue')
  },
  {
    // 8-4-1. Q&A 답변 검수 할당
    path: '/answer_confirm_assign/',
    name: 'AnswerConfirmAssign',
    component: () => import(/* webpackChunkName: "qna" */ '../views/AnswerConfirmAssign.vue')
  },
  {
    // 8-4-2. Q&A 답변 검수 List
    path: '/answer_confirm_list',
    name: 'AnswerConfirmList',
    component: () => import(/* webpackChunkName: "qna" */ '../views/AnswerConfirmList.vue')
  },
  {
    // 8-4-3. Q&A 답변 검수 View
    path: '/answer_confirm_view/:no',
    name: 'AnswerConfirmView',
    component: () => import(/* webpackChunkName: "qna" */ '../views/AnswerConfirmView.vue')
  },
  {
    // 8-4-4. Q&A 답변 검수 월/일자/워커별 작업현황 테이블
    path: '/answer_confirm_day_table',
    name: 'AnswerConfirmDayTable',
    component: () => import(/* webpackChunkName: "qna" */ '../views/AnswerConfirmDayTable.vue')
  },
  {
    // 8-5-1. Q&A 질문 현황
    path: '/question_check_list',
    name: 'QuestionCheckList',
    component: () => import(/* webpackChunkName: "qna" */ '../views/QuestionCheckList.vue')
  },
  {
    // 8-5-2. Q&A 답변 현황
    path: '/answer_check_list',
    name: 'nsnwerCheckList',
    component: () => import(/* webpackChunkName: "qna" */ '../views/AnswerCheckList.vue')
  },

  {
    // 9-1. 그룹관리 List(전 언어)
    path: '/group_list/:lan',
    name: 'GroupList',
    component: () => import(/* webpackChunkName: "group" */ '../views/GroupList.vue')
  },
  {
    // 9-1-1. 그룹관리 > 번역 작업현황보기
    path: '/trans_day_table/:lan',
    name: 'TransDayTable',
    component: () => import(/* webpackChunkName: "poi" */ '../views/TransDayTable.vue')
  },
  {
    // 9-2. 그룹관리 View(전 언어)
    path: '/group_view/:lan/:no',
    name: 'GroupView',
    component: () => import(/* webpackChunkName: "group" */ '../views/GroupView.vue')
  },
  {
    // 9-3. 작업물보기 (전 언어)
    path: '/group_view_work/:lan/:gno/:mno',
    name: 'GroupViewWork',
    component: () => import(/* webpackChunkName: "group" */ '../views/GroupViewWork.vue')
  },
  {
    // 9-4. 작업물보기 전체 (전 언어)
    path: '/group_view_work/:lan/:gno',
    name: 'GroupViewWorkAll',
    component: () => import(/* webpackChunkName: "group" */ '../views/GroupViewWork.vue')
  },

  {
    // 10-1. 권역별 작업현황
    path: '/region_status',
    name: 'RegionStatus',
    component: () => import(/* webpackChunkName: "group" */ '../views/RegionStatus.vue')
  },
  {
    // 10-2. 권역별 작업현황 > 일자/지역별 트리플 전송수량
    path: '/region_status_day_table',
    name: 'RegionStatusDayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/RegionStatusDayTable.vue')
  },
  {
    // 10-3. Q&A 작업현황
    path: '/qna_status',
    name: 'QnAStatus',
    component: () => import(/* webpackChunkName: "group" */ '../views/QnAStatus.vue')
  },
  {
    // 10-4. POI 작업현황
    path: '/poi_status',
    name: 'PoiStatus',
    component: () => import(/* webpackChunkName: "group" */ '../views/PoiStatus.vue')
  },
  {
    // 10-5. 이미지 작업현황
    path: '/image_status',
    name: 'ImageStatus',
    component: () => import(/* webpackChunkName: "group" */ '../views/ImageStatus.vue')
  },
  {
    // 10-5-2. 이미지 작업현황 > 워커별 작업량 보기
    path: '/image_day_table',
    name: 'ImageDayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/ImageDayTable.vue')
  },
  {
    // 10-5-3. 이미지 작업현황 > 워커별 월별/일별 작업량 보기
    path: '/image_day_table2',
    name: 'ImageDayTable2',
    component: () => import(/* webpackChunkName: "group" */ '../views/ImageDayTable2.vue')
  },
  {
    // 10-6. 번역 작업현황
    path: '/trans_status',
    name: 'TransStatus',
    component: () => import(/* webpackChunkName: "group" */ '../views/TransStatus.vue')
  },
  {
    // 10-7. 번역 POI 오류 분류현황
    path: '/trans_error_check_list',
    name: 'TransErrorCheckList',
    component: () => import(/* webpackChunkName: "group" */ '../views/TransErrorCheckList.vue')
  },
  {
    // 10-8. 권역별 트리플 현황
    path: '/triple_status',
    name: 'TripleStatus',
    component: () => import(/* webpackChunkName: "group" */ '../views/TripleStatus.vue')
  },

  {
    // 11-1. Q&A 뷰어 리스트
    path: '/qna_viewer_list/:area',
    name: 'QnAViewerList',
    component: () => import(/* webpackChunkName: "group" */ '../views/QnAViewerList.vue')
  },
  {
    // 11-1-1. Q&A 뷰어 보기
    path: '/qna_viewer_view/:area/:no',
    name: 'QnAViewerView',
    component: () => import(/* webpackChunkName: "group" */ '../views/QnAViewerView.vue')
  },
  {
    // 11-2. 번역 뷰어 리스트
    path: '/trans_viewer_list',
    name: 'TransViewerList',
    component: () => import(/* webpackChunkName: "group" */ '../views/TransViewerList.vue')
  },
  {
    // 11-2-1 번역 뷰어 보기
    path: '/trans_viewer_view/:tno',
    name: 'TransViewerView',
    component: () => import(/* webpackChunkName: "group" */ '../views/TransViewerView.vue')
  },

  {
    // 12-1-1 최종수정 > poi 수정 > 할당
    path: '/final_poi_assign/',
    name: 'FinalPoiAssign',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalPoiAssign.vue')
  },
  {
    // 12-1-2 최종수정 > poi 수정 > 리스트
    path: '/final_poi_list/',
    name: 'FinalPoiList',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalPoiList.vue')
  },
  {
    // 12-1-3 최종수정 > poi 수정 > 뷰
    path: '/final_poi_view/:no',
    name: 'FinalPoiView',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalPoiView.vue')
  },
  {
    // 12-1-4 최종수정 > poi 수정 > 일자별 작업량 보기
    path: '/final_poi_day_table',
    name: 'FinalPoiDayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalPoiDayTable.vue')
  },
  {
    // 12-2-1 최종수정 > poi 수정 > 할당
    path: '/final_triple_assign/',
    name: 'FinalTripleAssign',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalTripleAssign.vue')
  },
  {
    // 12-2-2 최종수정 > poi 수정 > 리스트
    path: '/final_triple_list/',
    name: 'FinalTripleList',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalTripleList.vue')
  },
  {
    // 12-2-3 최종수정 > poi 수정 > 뷰
    path: '/final_triple_view/:no',
    name: 'FinalTripleView',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalTripleView.vue')
  },

  {
    // 12-3-1 최종수정 > Q&A 수정 > 할당
    path: '/final_qna_assign/',
    name: 'FinalQnAAssign',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalQnAAssign.vue')
  },
  {
    // 12-3-2 최종수정 > Q&A 수정 > 리스트
    path: '/final_qna_list/',
    name: 'FinalQnAList',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalQnAList.vue')
  },
  {
    // 12-3-3 최종수정 > Q&A 수정 > 뷰
    path: '/final_qna_view/:no',
    name: 'FinalQnAView',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalQnAView.vue')
  },
  {
    // 12-3-4 최종수정 > Q&A 수정 > 일자별 작업량 보기
    path: '/final_qna_day_table',
    name: 'FinalQnADayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalQnADayTable.vue')
  },

  {
    // 12-4-1 최종수정 > 이미지 수정 > 할당
    path: '/final_image_assign/',
    name: 'FinalImageAssign',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalImageAssign.vue')
  },
  {
    // 12-4-2 최종수정 > 이미지 수정 > 리스트
    path: '/final_image_list/',
    name: 'FinalImageList',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalImageList.vue')
  },
  {
    // 12-4-3 최종수정 > 이미지 수정 > 뷰
    path: '/final_image_view/:img_no',
    name: 'FinalImageView',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalImageView.vue')
  },
  {
    // 12-4-4 최종수정 > 이미지 수정 > 일자별 작업량 보기
    path: '/final_image_day_table',
    name: 'FinalImageDayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/FinalImageDayTable.vue')
  },

  {
    // 13-1 캡션 수정 할당
    path: '/caption_assign',
    name: 'CaptionAssign',
    component: () => import(/* webpackChunkName: "group" */ '../views/CaptionAssign.vue')
  },
  {
    // 13-2 캡션 수정 일자별 테이블
    path: '/caption_day_table',
    name: 'CaptionDayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/CaptionDayTable.vue')
  },
  {
    // 13-3 캡션 수정 작업 리스트
    path: '/caption_list',
    name: 'CaptionList',
    component: () => import(/* webpackChunkName: "group" */ '../views/CaptionList.vue')
  },
  {
    // 13-4 캡션 수정 작업 뷰
    path: '/caption_view/:no',
    name: 'CaptionView',
    component: () => import(/* webpackChunkName: "group" */ '../views/CaptionView.vue')
  },

  {
    // 14-1 비식별화 할당
    path: '/blur_assign',
    name: 'BlurAssign',
    component: () => import(/* webpackChunkName: "group" */ '../views/BlurAssign.vue')
  },
  {
    // 14-2 비식별화 일자별 테이블
    path: '/blur_day_table',
    name: 'BlurDayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/BlurDayTable.vue')
  },
  {
    // 14-3 비식별화 작업 리스트
    path: '/blur_list',
    name: 'BlurList',
    component: () => import(/* webpackChunkName: "group" */ '../views/BlurList.vue')
  },
  {
    // 14-4 비식별화 작업 뷰
    path: '/blur_view/:no',
    name: 'BlurView',
    component: () => import(/* webpackChunkName: "group" */ '../views/BlurView.vue')
  },

  {
    // 15-1 데이터보완 리스트
    path: '/redeem_list',
    name: 'RedeemList',
    component: () => import(/* webpackChunkName: "group" */ '../views/RedeemList.vue')
  },
  {
    // 15-2 데이터보완 작업 뷰1 => QA 수정(POI정보, 캡션, QA질답세트 포함)
    path: '/redeem_view1/:no',
    name: 'RedeemView1',
    component: () => import(/* webpackChunkName: "group" */ '../views/RedeemView1.vue')
  },
  {
    // 15-3 데이터보완 작업 뷰2 => 비식별화 수정
    path: '/redeem_view2/:no',
    name: 'RedeemView2',
    component: () => import(/* webpackChunkName: "group" */ '../views/RedeemView2.vue')
  },

  {
    // 15-4 데이터보완2 리스트
    path: '/redeem2_list',
    name: 'Redeem2List',
    component: () => import(/* webpackChunkName: "group" */ '../views/Redeem2List.vue')
  },
  {
    // 15-5 데이터보완2 작업 뷰1 => 번역 수정
    path: '/redeem2_view/:no',
    name: 'Redeem2View',
    component: () => import(/* webpackChunkName: "group" */ '../views/Redeem2View.vue')
  },


  // {
  //   // 16-1 목업페이지1-1
  //   path: '/mockup1-1',
  //   name: 'M1-1',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/M1-1.vue')
  // },
  // {
  //   // 16-1 목업페이지1-2
  //   path: '/mockup1-2',
  //   name: 'M1-2',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/M1-2.vue')
  // },
  // {
  //   // 16-1 목업페이지1-3
  //   path: '/mockup1-3',
  //   name: 'M1-3',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/M1-3.vue')
  // },
  // {
  //   // 16-1 목업페이지1-4
  //   path: '/mockup1-4',
  //   name: 'M1-4',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/M1-4.vue')
  // },
  // {
  //   // 16-1 목업페이지2-1
  //   path: '/mockup2-1',
  //   name: 'M2-1',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/M2-1.vue')
  // },
  // {
  //   // 16-1 목업페이지2-2
  //   path: '/mockup2-2',
  //   name: 'M2-2',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/M2-2.vue')
  // },
  // {
  //   // 16-1 목업페이지2-3
  //   path: '/mockup2-3',
  //   name: 'M2-3',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/M2-3.vue')
  // },
  // {
  //   // 16-1 목업페이지2-4
  //   path: '/mockup2-4',
  //   name: 'M2-4',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/M2-4.vue')
  // },
  // {
  //   // 16-2 목업페이지90-1-1
  //   path: '/mockup90-1-1',
  //   name: 'mockup90-1-1',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/mockup90-1-1.vue')
  // },
  // {
  //   // 16-2 목업페이지90-1-2
  //   path: '/mockup90-1-2',
  //   name: 'mockup90-1-2',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/mockup90-1-2.vue')
  // },
  // {
  //   // 16-2 목업페이지90-1-3
  //   path: '/mockup90-1-3',
  //   name: 'mockup90-1-3',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/mockup90-1-3.vue')
  // },
  // {
  //   // 16-2 목업페이지90-2-1
  //   path: '/mockup90-2-1',
  //   name: 'mockup90-2-1',
  //   component: () => import(/* webpackChunkName: "group" */ '../views/mockup/mockup90-2-1.vue')
  // },



  // *******************************************************************************************

  {
    // 목업 리스트
    path: '/m_list/:no',
    name: 'MList',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MList.vue')
  },
  {
    // 목업 뷰1
    path: '/m_view1/:no',
    name: 'MView1',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MView1.vue')
  },
  {
    // 목업 뷰2
    path: '/m_view2/:no',
    name: 'MView2',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MView2.vue')
  },

  {
    // 목업 정제 리스트
    path: '/m_edit_list',
    name: 'MEditList',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MEditList.vue')
  },
  {
    // 목업 정제 뷰
    path: '/m_edit_view/:no',
    name: 'MEditView',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MEditView.vue')
  },

  {
    // 목업 번역 작업 할당 리스트
    path: '/m_trans_assign_list/:lan',
    name: 'MTransAssignList',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTransAssignList.vue')
  },
  {
    // 목업 번역 작업 일자별 테이블
    path: '/m_trans_assign_day_table',
    name: 'MTransAssignDayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTransAssignDayTable.vue')
  },
  {
    // 목업 번역 리스트
    path: '/m_trans_list/:lan',
    name: 'MTransList',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTransList.vue')
  },
  {
    // 목업 번역 뷰
    path: '/m_trans_view/:lan/:no',
    name: 'MTransView',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTransView.vue')
  },

  {
    // 목업 번역 검수 할당 리스트
    path: '/m_trans_confirm_assign_list/:lan',
    name: 'MTransConfirmAssignList',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTransConfirmAssignList.vue')
  },
  {
    // 목업 번역 검수 일자별 테이블
    path: '/m_trans_confirm_assign_day_table',
    name: 'MTransConfirmAssignDayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTransConfirmAssignDayTable.vue')
  },
  {
    // 목업 번역 검수 리스트
    path: '/m_trans_confirm_list/:lan',
    name: 'MTransConfirmList',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTransConfirmList.vue')
  },
  {
    // 목업 번역 검수 뷰
    path: '/m_trans_confirm_view/:lan/:no',
    name: 'MTransConfirmView',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTransConfirmView.vue')
  },

  {
    // 목업 트리플 작업 할당
    path: '/m_triple_assign_list',
    name: 'MTripleAssignList',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTripleAssignList.vue')
  },
  {
    // 목업 트리플 작업 일자별 테이블
    path: '/m_triple_assign_day_table',
    name: 'MTripleAssignDayTable',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTripleAssignDayTable.vue')
  },
  {
    // 목업 트리플 리스트
    path: '/m_triple_list',
    name: 'MTripleList',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTripleList.vue')
  },
  {
    // 목업 트리플 뷰
    path: '/m_triple_view/:no',
    name: 'MTripleView',
    component: () => import(/* webpackChunkName: "group" */ '../views/mockup90/MTripleView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
